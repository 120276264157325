import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { BsChevronRight, BsChevronDown } from 'react-icons/bs'

import { theme } from '@/styles/theme'
import { PxToVw } from '@/utils/convertPx'
import { isWebviewIOS } from '@/utils/isWebview'

import { StepBadge } from '@/components/elements/StepBadge'
import { AppDownloadLink } from '@/components/elements/AppDownloadLink'
import { Title } from '@/components/elements/Title'

type ShoppingStepProps = {
  step: number
  title: string
  description: ReactNode
  children: ReactNode
}

export const ShoppingStep = ({
  step,
  title,
  description,
  children,
}: ShoppingStepProps) => {
  const isIOS =
    typeof window !== 'undefined' && /[ \(]iP/.test(navigator.userAgent)

  return (
    <Wrapper>
      <ContentsContainer>
        <StepBadge step={step} />
        <Title as="h3" size={27} color={`${theme.colors.textColor}`}>
          {title}
        </Title>
        <Description>{description}</Description>
        {children}
        {step === 1 && (
          <>
            <SupayLogo>
              <StaticImage
                src="../../images/logo.png"
                alt="su-pay-logo"
                objectFit="contain"
                width={49}
                height={49}
              />
              <AppDownloadLink
                type={isIOS || isWebviewIOS ? 'iPhone' : 'Android'}
                iosWidth="133px"
                androidWidth="166px"
              />
            </SupayLogo>
          </>
        )}
      </ContentsContainer>
      {step < 3 && (
        <div>
          <StyledChevronRight size={40} color={`${theme.colors.supayBlue}`} />
          <StyledChevronDown size={30} color={`${theme.colors.supayBlue}`} />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1047px) {
    flex-direction: column;
    width: 100%;
  }
`

const ContentsContainer = styled.div`
  width: ${PxToVw(320, 1440)};
  padding: 0 20px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin: 30px 0 22px;
    line-height: 38px;
  }

  @media screen and (max-width: 1047px) {
    width: 100%;

    h3 {
      margin-top: 14px;
      margin-bottom: 13px;
      font-size: 20px;
      line-height: 1em;
    }
  }
`

const Description = styled.p`
  width: ${PxToVw(280, 1440)};
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 50px;

  @media screen and (max-width: 1047px) {
    font-size: 16px;
    line-height: 22px;
    width: 100%;
    margin-bottom: 23px;
  }
`

const SupayLogo = styled.div`
  display: none;

  @media screen and (max-width: 1047px) {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 16px;

    div + a {
      margin-left: 20px;
    }
  }
`

const StyledChevronRight = styled(BsChevronRight)`
  margin-top: 95px;
  display: block;

  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const StyledChevronDown = styled(BsChevronDown)`
  display: none;

  @media screen and (max-width: 1047px) {
    display: block;
    width: ${PxToVw(30, 375)};
    text-align: center;
    margin: 30px auto;
  }
`
