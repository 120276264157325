import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { graphql, Link } from 'gatsby'
import dayjs from 'dayjs'

import { PxToVw } from '@/utils/convertPx'
import { checkNoticePeriod } from '@/utils/noticePeriod'
import { isWebviewIOS } from '@/utils/isWebview'
import { theme } from '@/styles/theme'

import { contactFaqList } from '@/constants/contactFaq'

import { Layout } from '@/components/layouts/Layout'
import { Seo } from '@/components/Seo'
import { Title } from '@/components/elements/Title'
import { ShoppingUsage } from '@/components/layouts/ShoppingUsage'
import { Button } from '@/components/elements/Button'
import { BaseCard } from '@/components/elements/BaseCard'
import { Stack } from '@/components/elements/Stack'
import { Spacer } from '@/components/elements/Spacer'
import { AppDownloadLink } from '@/components/elements/AppDownloadLink'
import { ShoppingStep } from '@/components/layouts/ShoppingStep'
import { CampaignItems } from '@/components/layouts/CampaignItems'
import { ListItems } from '@/components/layouts/ListItems'

import { CampaignQuery, HoldStatus } from '@/interfaces/campaign'
import type { MicrocmsFaqConnection } from '@/interfaces/graphqlTypes'
import type { NoticeQuery, NoticeNode } from '@/interfaces/notice'

import { useCampaign } from '@/hooks/useCampaign'

import charge_complete from '../images/charge-complete.svg'
import collect_point from '../images/collect-point.svg'
import japan_map from '../images/japan-map.svg'
import payment_code from '../images/payment-code.svg'
import shopping_usage from '../images/shopping-usage.svg'
import start_app from '../images/start-app.svg'

export const query = graphql`
  query {
    allMicrocmsCampaign(sort: { order: DESC, fields: startdate }) {
      edges {
        node {
          campaignId
          title
          startdate
          enddate
          eyecatch {
            url
          }
        }
      }
    }
    allMicrocmsNotice(sort: { order: DESC, fields: start }) {
      edges {
        node {
          noticeId
          content
          notice
          start
          end
          display
        }
      }
    }
    allMicrocmsFaq(
      filter: {
        faqId: {
          in: [
            "banklist"
            "zyvxq9czypnq"
            "rnn33zskun4"
            "wa6uax9bgds"
            "chargerule"
          ]
        }
      }
    ) {
      edges {
        node {
          question
          faqId
          mediumCategory {
            id
          }
        }
      }
    }
  }
`

const { setHoldStatus } = useCampaign()

type Query = {
  allMicrocmsCampaign: CampaignQuery
  allMicrocmsFaq: MicrocmsFaqConnection
  allMicrocmsNotice: NoticeQuery
}

const shopUsage = [
  {
    title: (
      <>
        スマホひとつで
        <br />
        お支払い
      </>
    ),
    content: (
      <>
        かさばるカードを
        <br />
        持ち歩かなくても大丈夫
      </>
    ),
    img: shopping_usage,
    alt: 'スマホひとつでお支払い',
  },
  {
    title: (
      <>
        選べる
        <br />
        チャージ方法
      </>
    ),
    content: (
      <>
        どこでも銀行口座チャージ、
        <br />
        気軽にレジで現金チャージ
      </>
    ),
    img: charge_complete,
    alt: '選べるチャージ方法',
  },
  {
    title: (
      <>
        全国のトライアル店舗
        <br />
        で使える
      </>
    ),
    content: (
      <>
        全国のトライアル実店舗で
        <br />
        利用できて安心
      </>
    ),
    img: japan_map,
    alt: '全国のトライアルで使える',
  },
  {
    title: (
      <>
        ポイントが
        <br />
        たまる・使える
      </>
    ),
    content: (
      <>
        お支払いでポイントがたまる、
        <br />
        アプリでチェック！
      </>
    ),
    img: collect_point,
    alt: 'ポイントがたまる・使える',
  },
  {
    title: (
      <>
        とにかく
        <br />
        スーッとお支払い
      </>
    ),
    content: (
      <>
        すぐにバーコードが表示され、
        <br />
        スムーズにお支払い完了
      </>
    ),
    img: payment_code,
    alt: 'とにかくスーッとお支払い',
  },
]

const shoppingStep = [
  {
    step: 1,
    title: 'インストール',
    description: (
      <>
        「初めてアプリを使う方」
        <br />
        から会員登録
      </>
    ),
    img: start_app,
  },
  {
    step: 2,
    title: 'チャージ',
    description: (
      <>
        銀行からでも現金でも
        <br />
        チャージ可能
      </>
    ),
    img: charge_complete,
  },
  {
    step: 3,
    title: 'お買いもの♪',
    description: (
      <>
        レジでSU-PAYを見せて
        <br />
        スーッとお支払い完了
      </>
    ),
    img: payment_code,
  },
]

export default function IndexPage({ data }: { data: Query }): JSX.Element {
  const isIOS =
    typeof window !== 'undefined' && /[ \(]iP/.test(navigator.userAgent)

  data.allMicrocmsFaq.edges.sort((pre, next) => {
    return (
      contactFaqList.indexOf(pre.node.faqId!) -
      contactFaqList.indexOf(next.node.faqId!)
    )
  })

  let periodNotice: NoticeNode[] = []

  if (data.allMicrocmsNotice) {
    periodNotice = data.allMicrocmsNotice.edges.filter((edge) => {
      return edge.node.display && checkNoticePeriod(edge.node.start, edge.node.end)
    })
  }

  return (
    <Layout>
      <Seo canonical="https://su-pay.jp/" />
      <KeyVisual>
        <PcKeyVisualInner>
          <PcCircle
            imgWidth={116}
            imgHeight={50}
            left={140}
            top={270}
            width={138}
            height={138}
          >
            <StaticImage
              src="../images/earn-points.png"
              alt="ポイントがたまる！"
            />
          </PcCircle>
          <PcCircle
            imgWidth={92}
            imgHeight={76}
            right={170}
            top={127}
            width={138}
            height={138}
          >
            <StaticImage
              src="../images/charge-option.png"
              alt="選べるチャージ方法"
            />
          </PcCircle>
          <PcCircle
            imgWidth={92}
            imgHeight={76}
            right={90}
            bottom={127}
            width={138}
            height={138}
            opacity={0.8}
          >
            <StaticImage
              src="../images/cash-charge.png"
              alt="レジで現金チャージ"
            />
          </PcCircle>
          <PcCircle
            imgWidth={92}
            imgHeight={76}
            right={190}
            bottom={50}
            width={138}
            height={138}
            opacity={0.8}
          >
            <StaticImage src="../images/bank-charge.png" alt="銀行チャージ" />
          </PcCircle>
          <PcCircle
            left={-86}
            top={100}
            width={206}
            height={206}
            opacity={0.6}
          />
          <PcCircle
            left={66}
            bottom={87}
            width={66}
            height={66}
            opacity={0.4}
          />
          <PcCircle top={116} right={66} width={38} height={38} opacity={0.7} />
          <PcCircle
            top={200}
            right={-28}
            width={88}
            height={88}
            opacity={0.5}
          />
          <Dot
            pcPosition={{ left: 1, bottom: 30 }}
            pcSize={{ width: 331, height: 213 }}
          >
            <StaticImage src="../images/dotted1.png" alt="" />
          </Dot>
          <Dot
            pcPosition={{ right: 1, bottom: 40 }}
            pcSize={{ width: 322, height: 281 }}
          >
            <StaticImage src="../images/dotted2.png" alt="" />
          </Dot>
          <TitleWrapper>
            <Stack flexDirection="column" alignItems="flex-start" spacing={0}>
              <StaticImage
                className="suuto-pc"
                src="../images/suuto.svg"
                alt="スーッとお支払い、スーペイ"
              />
              <Spacer h={{ sp: 26, pc: 35 }}></Spacer>
              <Title as="h1" size={18} color="white" weight="normal">
                <span>全国の</span>
                <StaticImage src="../images/trial.svg" alt="トライアルロゴ" />
                <span>で使える決済アプリ</span>
              </Title>
              <Spacer h={{ sp: 18, pc: 70 }}></Spacer>
              <AppDownload>
                <StaticImage
                  src="../images/logo.png"
                  alt="SU-PAYアプリロゴ"
                  width={65}
                  height={65}
                />
                <Spacer w={{ sp: 20, pc: 20 }} />
                <AppDownloadLink type="iPhone" iosWidth="12.3vw" />
                <AppDownloadLink type="Android" androidWidth="15.2vw" />
              </AppDownload>
            </Stack>
          </TitleWrapper>
          <SmartPhoneWrapper>
            <StaticImage
              className="smart_phone"
              src="../images/smart-phone.png"
              alt="スマホ"
            />
            <StaticImage
              className="lines"
              src="../images/three-lines.svg"
              alt="線"
              layout="fullWidth"
            />
          </SmartPhoneWrapper>
        </PcKeyVisualInner>
        <SpKeyVisualInner>
          <TitleWrapper>
            <StaticImage
              className="suuto-sp"
              src="../images/suuto-sp.svg"
              alt="スーッとお支払い、スーペイ"
            />
            <Title as="h1" size={18} color="white" weight="normal">
              全国の
              <StaticImage src="../images/trial.svg" alt="トライアルロゴ" />
              で使える決済アプリ
            </Title>
          </TitleWrapper>
          <SmartPhoneWrapper>
            <StaticImage
              className="smart_phone"
              src="../images/smart-phone.png"
              alt="スマホ"
            />
            <StaticImage
              className="lines"
              src="../images/three-lines.svg"
              alt="線"
            />
            <SpCircle
              imgWidth={'84%'}
              top={30}
              left={-87}
              width={124}
              height={124}
            >
              <StaticImage
                src="../images/earn-points.png"
                objectFit="contain"
                alt="ポイントがたまる！"
              />
            </SpCircle>
            <SpCircle
              imgWidth={'67%'}
              right={-58}
              bottom={20}
              width={118}
              height={118}
              zIndex={9}
            >
              <StaticImage
                src="../images/charge-option.png"
                objectFit="contain"
                alt="選べるチャージ方法"
              />
            </SpCircle>
            <Dot
              spSize={{ width: '331px' }}
              spPosition={{ left: '-187px', bottom: '30px' }}
            >
              <StaticImage src="../images/dotted1.png" alt="" />
            </Dot>
            <Dot
              spSize={{ width: 'auto' }}
              spPosition={{ right: '-187px', bottom: '30px' }}
            >
              <StaticImage src="../images/dotted2.png" alt="" />
            </Dot>
            <SpCircle
              bottom={50}
              left={-127}
              width={80}
              height={80}
              opacity={0.6}
            />
            <SpCircle
              bottom={40}
              left={-67}
              width={50}
              height={50}
              opacity={0.6}
            />
            <SpCircle
              bottom={100}
              right={-127}
              width={70}
              height={70}
              opacity={0.7}
            />
            <SpCircle
              top={40}
              right={-78}
              width={30}
              height={30}
              opacity={0.6}
            />
          </SmartPhoneWrapper>
        </SpKeyVisualInner>
      </KeyVisual>
      <Download>
        <StaticImage
          src="../images/logo.png"
          alt="supayロゴ"
          width={49}
          height={49}
        />
        <AppDownloadLink
          type={isIOS || isWebviewIOS ? 'iPhone' : 'Android'}
          iosWidth="133px"
          androidWidth="168px"
        />
      </Download>
      {periodNotice.length > 0 && (
        <Notice>
          {periodNotice.map((notice) => (
            <ListItems
              key={notice.node.noticeId}
              link={`/notices/${notice.node.noticeId}/`}
              item={notice.node.notice}
              showBadge={false}
              date={dayjs(notice.node.start).format('YYYY/MM/DD(dd)')}
            />
          ))}
        </Notice>
      )}
      <Section bgcolor="rgba(0,70,155,0.1)">
        <SectionInner>
          <Title as="h2">SU-PAYとは</Title>
          <ShopUsageStack flexDirection="row" spacing={0}>
            {shopUsage.map(({ title, content, img, alt }) => (
              <ShoppingUsage key={alt} title={title} content={content}>
                <img src={img} alt={alt}></img>
              </ShoppingUsage>
            ))}
          </ShopUsageStack>
          <LinkButton>
            <Link to={'/faq/bankaccount/banklist/'}>
              <Button showArrowRight>対応銀行一覧はこちら</Button>
            </Link>
          </LinkButton>
        </SectionInner>
      </Section>
      <Section bgcolor="white">
        <SectionInner>
          <Title as="h2">キャンペーン</Title>
          <CampaignList>
            {data.allMicrocmsCampaign.edges
              .filter(
                (campaign) => new Date(campaign.node.enddate) >= new Date()
              )
              .slice(0, 3)
              .map((campaign) => {
                const holdStatus: HoldStatus = setHoldStatus(
                  campaign.node.startdate,
                  campaign.node.enddate
                )
                return (
                  <CampaignItems
                    key={campaign.node.campaignId}
                    campaignId={campaign.node.campaignId}
                    holdStatus={holdStatus}
                    eyecatch={campaign.node.eyecatch}
                    startdate={campaign.node.startdate}
                    enddate={campaign.node.enddate}
                    title={campaign.node.title}
                  />
                )
              })}
          </CampaignList>
          <LinkButton>
            <Link to={'/campaign/'}>
              <Button showArrowRight>キャンペーン一覧へ</Button>
            </Link>
          </LinkButton>
        </SectionInner>
      </Section>
      <Section bgcolor="rgba(0,70,155,0.1)">
        <SectionInner>
          <Title as="h2">SU-PAYの始め方</Title>
          <ShoppingStepStack
            flexDirection="row"
            spacing={0}
            switchDirection
            alignItems={'flex-start'}
          >
            {shoppingStep.map(({ step, title, description, img }) => (
              <ShoppingStep
                key={step}
                step={step}
                title={title}
                description={description}
              >
                <img src={img} alt={title}></img>
              </ShoppingStep>
            ))}
          </ShoppingStepStack>
          <TrialBaseCard color="white">
            <StaticImage
              src="../images/trial-card.svg"
              height={53}
              alt="プリペイドカード"
            />
            <Title as="h4" size={24} color={theme.colors.supayBlue}>
              トライアルプリペイドカードをお使いのお客様は、
              <br />
              残高とポイントをSU-PAYに引き継ぎできます！
            </Title>
          </TrialBaseCard>
          <LinkButton>
            <Link to={'/howto/'}>
              <Button showArrowRight>詳しい使い方を見る</Button>
            </Link>
          </LinkButton>
        </SectionInner>
      </Section>
      <Section bgcolor="white">
        <SectionInner>
          <Title as="h2">よくあるご質問</Title>
          <QuestionList>
            {data.allMicrocmsFaq.edges.map((faq) => (
              <ListItems
                key={faq.node.faqId!}
                link={`/faq/${faq.node.mediumCategory?.id!}/${faq.node
                  .faqId!}/`}
                item={faq.node.question!}
              />
            ))}
          </QuestionList>
          <LinkButton>
            <Link to={'/faq/'}>
              <Button showArrowRight>よくあるご質問一覧へ</Button>
            </Link>
          </LinkButton>
        </SectionInner>
      </Section>
    </Layout>
  )
}

const Notice = styled.ul`
  width: ${(props) => props.theme.containerWidth};
  margin: 0 auto;
  padding: 18px 0;

  @media screen and (max-width: 1047px) {
    width: 100%;
    padding: 10px;
  }
`;

const SectionInner = styled.div`
  width: ${(props) => props.theme.containerWidth};
  margin: 0 auto;

  @media screen and (max-width: 1047px) {
    width: 100%;
  }
`

const KeyVisual = styled.section`
  position: relative;
  height: 41.2vw;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.supayBlue};

  @media screen and (max-width: 1047px) {
    height: 534px;
  }
`

const PcKeyVisualInner = styled.div`
  width: 795px;
  margin: 0 auto;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const SpKeyVisualInner = styled.div`
  width: 100%;
  display: none;
  padding: 0 26px;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1047px) {
    display: flex;
  }
`

const Dot = styled.div<{
  pcPosition?: { top?: number; right?: number; bottom?: number; left?: number }
  spPosition?: { top?: string; right?: string; bottom?: string; left?: string }
  pcSize?: { width?: number; height?: number }
  spSize?: { width?: string; height?: string }
}>`
  position: absolute;

  ${({ pcPosition }) =>
    pcPosition?.top && `top: ${PxToVw(pcPosition.top, 1440)};`}
  ${({ pcPosition }) =>
    pcPosition?.right && `right: ${PxToVw(pcPosition.right, 1440)};`}
  ${({ pcPosition }) =>
    pcPosition?.bottom && `bottom: ${PxToVw(pcPosition.bottom, 1440)};`}
  ${({ pcPosition }) =>
    pcPosition?.left && `left: ${PxToVw(pcPosition.left, 1440)};`}

  ${({ pcSize }) => pcSize?.width && `width: ${PxToVw(pcSize.width, 1440)}`};
  ${({ pcSize }) => pcSize?.height && `height: ${PxToVw(pcSize.height, 1440)}`};

  @media screen and (max-width: 1047px) {
    z-index: -1;
    ${({ spPosition }) => spPosition?.top && `top: ${spPosition.top};`}
    ${({ spPosition }) => spPosition?.right && `right: ${spPosition.right};`}
    ${({ spPosition }) => spPosition?.bottom && `bottom: ${spPosition.bottom};`}
    ${({ spPosition }) => spPosition?.left && `left: ${spPosition.left};`}

    ${({ spSize }) => spSize?.width && `width: ${spSize.width}`};
    ${({ spSize }) => spSize?.height && `height: ${spSize.height}`};
  }
`

const AppDownload = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.6);

  padding: 24px 25px 25px 25.5px;
  border-radius: 20px;
  width: 130%;

  overflow: hidden;

  a + a {
    margin-left: 15px;
  }

  [type='iPhone'] {
    max-width: 177px;
  }

  [type='Android'] {
    max-width: 220px;
  }
`

const Section = styled.section<{
  bgcolor: string
}>`
  width: 100%;
  position: relative;
  padding: 120px 0 130px;
  background-color: ${({ bgcolor }) => bgcolor};

  &:nth-child(3) {
    padding: 130px 0;
  }

  &:last-child {
    padding: 120px 0 0;
  }

  h2 {
    line-height: 34px;
  }

  @media screen and (max-width: 1047px) {
    width: 100%;

    margin: 0 auto;

    &:nth-child(3),
    &:nth-child(4) {
      padding: 65px ${(props) => props.theme.containerMargin} 64px;
    }

    &:nth-child(5) {
      padding: 80px ${(props) => props.theme.containerMargin} 65px;
    }

    &:last-child {
      padding: 80px ${(props) => props.theme.containerMargin} 0;
    }

    h2 {
      font-size: 30px;
      line-height: 30px;
    }
  }
`

const SmartPhoneWrapper = styled.div`
  position: relative;
  z-index: 9;
  transform: translateY(10%);
  margin-left: 34px;

  .smart_phone {
    height: 40vw;
    width: 23.96vw;
    z-index: 9;
  }

  .lines {
    position: absolute;
    right: ${PxToVw(-20, 1440)};
    top: ${PxToVw(6, 1440)};
    width: ${PxToVw(20, 1440)};
  }

  @media screen and (max-width: 1047px) {
    margin-left: 0;

    .smart_phone {
      width: 175px;
      height: 300px;
    }

    .lines {
      width: 20px;
      top: -10px;
      right: -20px;
    }
  }

  @media screen and (max-width: 374px) {
    .smart_phone {
      width: 46.66vw;
      height: 80vw;
    }
  }
`

const TitleWrapper = styled.div`
  position: relative;
  transform: translateY(-20px);

  h1 {
    display: flex;
    align-items: center;

    span {
      z-index: 8;
    }

    .gatsby-image-wrapper {
      margin: 0 4px;
    }
  }

  .suuto-pc {
    z-index: ${theme.zIndex.low};
    width: 28.82vw;
    height: 7.36vw;
  }

  .suuto-sp {
    display: none;
  }

  @media screen and (max-width: 1137px) {
    h1 {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1047px) {
    transform: translateY(0);

    h1 {
      justify-content: center;
      font-size: 14px;
      margin: 26px auto;
      line-height: 1;

      .gatsby-image-wrapper {
        height: 12px;
        width: 75px;
      }
    }

    .suuto-pc {
      display: none;
    }

    .suuto-sp {
      display: block;
    }
  }
`

const Circle = css`
  position: absolute;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.accentBlue};
  display: flex;
  align-items: center;
  justify-content: center;
`

const PcCircle = styled.div<{
  opacity?: number
  imgWidth?: number
  imgHeight?: number
  top?: number
  right?: number
  bottom?: number
  left?: number
  width: number
  height: number
}>`
  ${Circle}

  ${({ opacity }) => opacity && `opacity: ${opacity}`};

  ${({ top }) => top && `top: ${PxToVw(top, 1440)};`}
  ${({ right }) => right && `right: ${PxToVw(right, 1440)};`}
  ${({ bottom }) => bottom && `bottom: ${PxToVw(bottom, 1440)};`}
  ${({ left }) => left && `left: ${PxToVw(left, 1440)};`}

  width: ${({ width }) => PxToVw(width, 1440)};
  height: ${({ height }) => PxToVw(height, 1440)};

  .gatsby-image-wrapper {
    ${({ imgWidth }) => imgWidth && `width: ${PxToVw(imgWidth, 1440)}`};
    ${({ imgHeight }) => imgHeight && `height: ${PxToVw(imgHeight, 1440)}`};
  }
`

const SpCircle = styled.div<{
  opacity?: number
  imgWidth?: string
  top?: number
  right?: number
  bottom?: number
  left?: number
  width: number
  height: number
  zIndex?: number
}>`
  ${Circle}

  ${({ opacity }) => opacity && `opacity: ${opacity};`};
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`};

  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  ${({ top }) => top && `top: ${top}px;`}
  ${({ right }) => right && `right: ${right}px;`}
  ${({ bottom }) => bottom && `bottom: ${bottom}px;`}
  ${({ left }) => left && `left: ${left}px;`}

  .gatsby-image-wrapper {
    ${({ imgWidth }) => imgWidth && `width: ${imgWidth}`};
  }

  @media screen and (max-width: 374px) {
    width: ${({ width }) => PxToVw(width, 375)};
    height: ${({ height }) => PxToVw(height, 375)};

    ${({ left }) => left && `left: ${PxToVw(left, 375)};`}
    ${({ right }) => right && `right: ${PxToVw(right, 375)};`}
  }
`

const Download = styled.section`
  display: none;

  div + a {
    margin-left: 20px;
  }

  @media screen and (max-width: 1047px) {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
  }
`

const ShopUsageStack = styled(Stack)`
  flex-wrap: wrap;
  margin: 90px 0 60px;

  @media screen and (max-width: 1047px) {
    margin: 40px 0 50px;
  }
`

const ShoppingStepStack = styled(Stack)`
  margin-top: 100px;

  @media screen and (max-width: 1047px) {
    margin-top: 80px;
  }
`

const TrialBaseCard = styled(BaseCard)`
  display: flex;
  align-items: center;
  width: 72%;
  margin: 80px auto;
  padding: 33px 24px 33px 40px;

  .gatsby-image-wrapper {
    flex-shrink: 0;
    margin-right: 25px;
  }

  h4 {
    text-align: left;
    line-height: 34px;

    br {
      display: block;
    }
  }

  @media screen and (max-width: 1047px) {
    width: 100%;
    padding: 22px;
    margin: 48px auto 34px;
    align-items: flex-start;

    .gatsby-image-wrapper {
      margin-right: 17px;
    }

    h4 {
      line-height: 22px;

      br {
        display: none;
      }
    }
  }
`

const LinkButton = styled.div`
  display: flex;
  justify-content: center;
`

const QuestionList = styled.ul`
  width: 100%;
  margin: 100px 0 80px;

  @media screen and (max-width: 1047px) {
    margin: 65px 0 50px;
  }
`

const CampaignList = styled.ul`
  margin: 100px 0 80px;

  @media screen and (max-width: 1047px) {
    margin: 45px 0 56px;
  }
`
