import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Title } from '../elements/Title'

type Props = {
  title: ReactNode
  content: ReactNode
  children: ReactNode
}

export const ShoppingUsage = ({ title, content, children }: Props) => {
  return (
    <Wrapper>
      {children}
      <Title as="h3">{title}</Title>
      <p>{content}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  width: 320px;

  &:nth-child(4),
  &:nth-child(5) {
    margin-top: 38px;
  }

  h3 {
    margin-top: 35px;
    margin-bottom: 25px;
    font-size: 27px;
    line-height: 34px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
  }

  .gatsby-image-wrapper {
    margin-left: 60px;
    height: 205px;
  }

  @media screen and (max-width: 1047px) {
    padding: 0 10px;
    width: 260px;

    h3 {
      margin-top: 17px;
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 20px;

      br {
        display: none;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
    }

    .gatsby-image-wrapper {
      height: 117px;
      margin-left: 18px;
    }
  }

  @media screen and (max-width: 846px) {
    &:nth-child(3) {
      margin-top: 38px;
    }
  }

  @media screen and (max-width: 586px) {
    & + & {
      margin-top: 22px;
    }
  }
`
